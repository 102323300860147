<template>
  <div>
    <Tabs :activeTab="1" />

    <form id="developerDataForm">
      <div class="content-wrap-container">
        <div class="row mb-3">
          <div class="col">
            <div class="form-group">
              <label for="name">Nombre del Proyecto</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="name"
                class="form-control"
                v-model="form.name"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="address">Dirección</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="address"
                class="form-control"
                v-model="form.address"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="georeference">Georeferencia</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="georeference"
                v-mask="'##.######,##.######'"
                class="form-control"
                v-model="form.georeference"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="person_type">Tipo de Persona</label>
              <select
                :readonly="isReadonly"
                v-model="form.person_type"
                class="form-control"
                id="person_type"
              >
                <option value="" disabled>Seleccione el tipo de persona</option>
                <option
                  :value="type.id"
                  v-for="type in person_type_list"
                  :key="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="terrain_type">Tipo de Terreno</label>
              <select
                :readonly="isReadonly"
                v-model="form.terrain_type"
                class="form-control"
                id="terrain_type"
              >
                <option value="" disabled>Seleccione el tipo de terreno</option>
                <option
                  :value="type.id"
                  v-for="type in terrain_type_list"
                  :key="type.id"
                >
                  {{ type.name }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <div class="form-group">
              <label for="project_manager">Responsable del Proyecto</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="project_manager"
                class="form-control"
                v-model="form.project_manager"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="identity_card">Cédula de Identidad / Pasaporte</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="identity_card"
                v-mask="'###-#######-#'"
                class="form-control"
                v-model="form.identity_card"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="phone_number">Teléfono</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="phone_number"
                v-mask="'(###) ### ####'"
                class="form-control"
                v-model="form.phone_number"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="cell_phone_number">Celular</label>
              <input
                :readonly="isReadonly"
                type="text"
                v-mask="'(###) ### ####'"
                id="cell_phone_number"
                class="form-control"
                v-model="form.cell_phone_number"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="email">Correo Electrónico</label>
              <input
                :readonly="isReadonly"
                type="text"
                id="email"
                class="form-control"
                v-model="form.email"
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-9">
            <div class="form-group">
              <label for="description">Descripción del Proyecto</label>
              <textarea
                :readonly="isReadonly"
                type="text"
                id="description"
                class="form-control"
                v-model="form.description"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="flex-content-btn">
              <div class="content-btn-inside">
                <div>
                  <b-button
                    variant="outline-success"
                    class="mx-4 btn-success btn text-white"
                    @click="enableDisableFields"
                  >
                    <i class="fa fa-pencil"></i>&nbsp; Editar
                  </b-button>
                  <b-button
                    v-on:click="handleSubmit"
                    variant="primary"
                    class="mx-4 btn-success btn"
                    :disabled="isReadonly"
                  >
                    <i class="fa fa-save"></i>&nbsp; Guardar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p align="left"><strong>Alcance del Proyecto</strong></p>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label for="lot_area_m2"
                    >Área del solar (metros cuadrados)</label
                  >
                  <input
                    :readonly="isReadonly"
                    id="lot_area_m2"
                    class="form-control"
                    type="number"
                    placeholder="0"
                    v-model="form.lot_area_m2"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="lot_price">Precio del solar (RD$)</label>
                  <input
                    :readonly="isReadonly"
                    type="number"
                    v-mask="currencyMask"
                    id="lot_price"
                    class="form-control"
                    placeholder="0.00"
                    v-model="form.lot_price"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="home_prices_from"
                    >Precio de viviendas (desde)</label
                  >
                  <input
                    :readonly="isReadonly"
                    v-mask="currencyMask"
                    type="number"
                    id="home_prices_from"
                    class="form-control"
                    placeholder="0.00"
                    v-model="form.home_prices_from"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="home_prices_up_to"
                    >Precio de viviendas (hasta)</label
                  >
                  <input
                    :readonly="isReadonly"
                    v-mask="currencyMask"
                    type="number"
                    id="home_prices_up_to"
                    class="form-control"
                    placeholder="0.00"
                    v-model="form.home_prices_up_to"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="total_meters_habitable_salable"
                    >Total m2 habitable / vendible</label
                  >
                  <input
                    :readonly="isReadonly"
                    type="number"
                    id="total_meters_habitable_salable"
                    class="form-control"
                    placeholder="0"
                    v-model="form.total_meters_habitable_salable"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3 mb-5">
              <div class="col-sm">
                <div class="form-group">
                  <label for="cost_meters_habitable_salable"
                    >Costo m2 habitable / vendible</label
                  >
                  <input
                    :readonly="isReadonly"
                    v-mask="currencyMask"
                    type="number"
                    id="cost_meters_habitable_salable"
                    class="form-control"
                    placeholder="0.00"
                    v-model="form.cost_meters_habitable_salable"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="parking_lots_units"
                    >Cantidad de parqueos (unidades)</label
                  >
                  <input
                    :readonly="isReadonly"
                    id="parking_lots_units"
                    class="form-control"
                    type="number"
                    placeholder="0"
                    v-model="form.parking_lots_units"
                  />
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label for="total_green_area_m2">Total área verde (m2)</label>
                  <input
                    :readonly="isReadonly"
                    id="total_green_area_m2"
                    class="form-control"
                    type="number"
                    placeholder="0"
                    v-model="form.total_green_area_m2"
                  />
                </div>
              </div>
              <div class="col-sm"></div>
              <div class="col-sm"></div>
            </div>
          </div>
        </div>
      </div>
      <!--end content-wrap-container-->

      <div class="content-wrap-container">
        <div class="row mb-3">
          <div class="col-sm">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>TIPO DE VIVIENDA</th>
                  <th>CANTIDAD A CONSTRUIR</th>
                  <th>% POR TIPOLOGIA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>VIVIENDAS SUBSIDIADAS (VISUB)</th>
                  <td>
                    <input
                      :readonly="isReadonly"
                      type="number"
                      v-model="form.visub"
                      class="form-control"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <th>VIVIENDAS INTERÉS PRIORITARIO (VIP)</th>
                  <td>
                    <input
                      :readonly="isReadonly"
                      type="number"
                      v-model="form.vip"
                      class="form-control"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <th>VIVIENDAS INTERÉS SOCIAL (VIS)</th>
                  <td>
                    <input
                      :readonly="isReadonly"
                      type="number"
                      v-model="form.vis"
                      class="form-control"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <th>VIVIENDAS BAJO COSTO (VBC)</th>
                  <td>
                    <input
                      :readonly="isReadonly"
                      type="number"
                      v-model="form.vbc"
                      class="form-control"
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <th>TOTALES</th>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th>VIVIENDAS SOCIALES</th>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm">
            <p align="left" class="p-1">Equipamiento Area Recreación</p>
            <table class="table table-bordered" id="recreation_equip">
              <tr v-for="equipment in equipments" :key="equipment.id">
                <td>
                  <input
                    :readonly="isReadonly"
                    type="checkbox"
                    v-on:change="equipmentSelected"
                    :value="equipment.id"
                    :id="equipment.id"
                    class="equipment_item"
                  />
                  <label>{{ equipment.name }}</label>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!--end content-wrap-container-->
    </form>
  </div>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
import Tabs from "../../components/Project/Tabs.vue";
import {
  createProject,
  updateProject,
  getProjectInformation,
  getPersonType,
  getTerrainType,
  getRecreationEquipments,
} from "@/services/projectAPI";
import Swal from "sweetalert2";
import { getDeveloperData } from "@/services/developerData";

export default {
  components: { Tabs },
  data() {
    return {
      currencyMask,
      developer: null,
      loading: false,
      isReadonly: true,
      documentTypes: [],
      person_type_list: [],
      terrain_type_list: [],
      equipments: [],
      form: {
        user_id: null,
        name: "",
        address: "",
        georeference: "",
        person_type: null,
        terrain_type: null,
        project_manager: "",
        identity_card: "",
        phone_number: "",
        cell_phone_number: "",
        email: "",
        description: "",
        lot_area_m2: null,
        unit_of_measurement: "",
        lot_price: null,
        home_prices_from: null,
        home_prices_up_to: null,
        total_meters_habitable_salable: null,
        cost_meters_habitable_salable: null,
        parking_lots_units: null,
        total_green_area_m2: null,
        visub: null,
        vip: null,
        vis: null,
        vbc: null,
        equipments_selected: [],
        equipments_selected_string: String,
      },
    };
  },
  methods: {
    enableDisableFields() {
      this.isReadonly = !this.isReadonly;
    },
    handleSubmit() {
      this.equipmentSelected();
      var form = { ...this.form };

      if (form.cost_meters_habitable_salable != null) {
        form.cost_meters_habitable_salable =
          form.cost_meters_habitable_salable.replace(/-|\s|,/g, "");
      }

      if (form.lot_price != null) {
        form.lot_price = form.lot_price.replace(/-|\s|,/g, "");
      }

      if (form.home_prices_from != null) {
        form.home_prices_from = form.home_prices_from.replace(/-|\s|,/g, "");
      }

      if (form.home_prices_up_to != null) {
        form.home_prices_up_to = form.home_prices_up_to.replace(/-|\s|,/g, "");
      }

      if (this.projectId != null && this.projectId != undefined) {
        updateProject(this.projectId, form)
          .then((res) => {
            Swal.fire({
              title: "¡Proceso completado!",
              text: res.message,
              icon: "success",
              confirmButtonText: "Listo",
            });
            this.enableDisableFields();
          })
          .catch(() => {
            Swal.fire({
              title: "¡Ha ocurrido un error!",
              text: "Favor intente de nuevo o contacte un técnico.",
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      } else {
        createProject(form)
          .then((data) => {
            if (!data.error) {
              Swal.fire({
                title: "¡Proceso completado!",
                text: data.message,
                icon: "success",
                confirmButtonText: "Listo",
              });

              this.$router.push("Projects");
            } else {
              Swal.fire({
                title: "¡Ha ocurrido un error!",
                text: data.message,
                icon: "error",
                confirmButtonText: "Volver",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "¡Ha ocurrido un error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "Volver",
            });
          });
      }
    },
    getProjectData() {
      let inputsLoaded = document.getElementsByClassName("equipment_item");
      console.log("input  sLoaded", [].slice.call(inputsLoaded));
      if (this.projectId != null && this.projectId != undefined) {
        getProjectInformation(this.projectId)
          .then((data) => {
            this.form = data.project;
            this.form.lot_area_m2 = data.project.scopes[0].lot_area_m2;
            this.form.lot_price = data.project.scopes[0].lot_price;
            this.form.home_prices_from =
              data.project.scopes[0].home_prices_from;
            this.form.home_prices_up_to =
              data.project.scopes[0].home_prices_up_to;
            this.form.total_meters_habitable_salable =
              data.project.scopes[0].total_meters_habitable_salable;
            this.form.cost_meters_habitable_salable =
              data.project.scopes[0].cost_meters_habitable_salable;
            this.form.parking_lots_units =
              data.project.scopes[0].parking_lots_units;
            this.form.total_green_area_m2 =
              data.project.scopes[0].total_green_area_m2;
            this.form.visub = data.project.scopes[0].visub;
            this.form.vip = data.project.scopes[0].vip;
            this.form.vis = data.project.scopes[0].vis;
            this.form.vbc = data.project.scopes[0].vbc;

            for (let i = 0; i < inputsLoaded.length; i++) {
              for (let i = 0; i < data.equipments.length; i++) {
                if (data.equipments[i].id === inputsLoaded[i].value) {
                  inputsLoaded[i].checked = true;
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    getPersonTypeList() {
      getPersonType()
        .then((data) => {
          if (data.success) {
            this.person_type_list = data.personTypes;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getTerrainTypeList() {
      getTerrainType()
        .then((data) => {
          this.terrain_type_list = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getEquipments() {
      getRecreationEquipments()
        .then((data) => {
          if (data.success) {
            this.equipments = data.equipments;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "Volver",
          });
        });
    },
    equipmentSelected() {
      let inputs = document.getElementsByClassName("equipment_item");
      this.form.equipments_selected = [];

      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].checked) {
          this.form.equipments_selected.push(inputs[i].value);
        }
      }

      this.form.equipments_selected_string = JSON.stringify(
        this.form.equipments_selected
      );
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
  },
  mounted() {
    this.documentTypes = [
      "Registro Mercantil",
      "Estatutos de la empresa",
      "Acta de Asamblea(última)",
      "Certificacion RNC",
      "Certificacion TSS",
      "Presentacion de la empresa(Curriculum)",
      "Tesoreria de la seguridad social(TSS)",
      "Cédula del Representante Legal",
    ];

    this.getProjectData();
    this.getPersonTypeList();
    this.getTerrainTypeList();
    this.getEquipments();

    getDeveloperData().then((data) => {
      this.developer = data;
      this.form.user_id = data.id;
    });
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
form .form-group {
  text-align: start;
}
table tr th,
table tr td {
  text-align: start;
}

table tr td:last-child {
  text-align: right !important;
}
#recreation_equip {
  width: 80%;
}
#recreation_equip tr td {
  text-align: left !important;
}
.flex-content-btn {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.content-btn-inside {
  width: 100%;
  float: left;
  margin: 6% 0;
}
.flex-content-btn button {
  width: 40%;
  float: left;
  margin: 0 10px !important;
}
</style>
